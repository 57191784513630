<template>
  <div class="invite">
       <div class="invite-form">
           <h2>欢迎来到享设计</h2>

            <c-image iUrl="icon-invite" iClass="icon" iWidth="80" iHeight="59"></c-image>

            <div class="cue">
                <h3 class="s_1">如果您有邀请码，请在下方输入</h3>
                <h3 class="s_2">没有邀请码，请点击确定</h3>
            </div>

            <div class="form form_common">
                <van-form>
                     <div class="style2">
                        <van-field v-model="code" :disabled="codeDisabled" autocomplete="off" type="text" name="invitCode" placeholder="邀请码（没有可不填）"/>
                    </div>
                </van-form>

                <!-- <div class="invite-agree">
                    注册使用即表明同意<i  @click.stop="onPageToAgree">《网站协议》</i>和<i @click.stop="onPageToCopyright">《版权声明》</i>
                </div> -->

                <c-button @btnClick="onSubmit">确定</c-button>
            </div>
       </div>
  </div>
</template>

<script>
import { Field, Form, Checkbox } from 'vant';
import CButton from '../../components/form/CButton.vue';
import CImage from '../../components/CImage.vue';
import { mapActions } from 'vuex'
import {dialogAlert2} from '../../lib/util'
export default {
    components: {
        [CButton.name]: CButton,
        [CImage.name]: CImage,
        [Field.name]: Field,
        [Form.name]: Form,
        [Checkbox.name]: Checkbox,
    },
    data() {
        return {
            code: '',

            codeDisabled: false
        }
    },
    methods: {
        ...mapActions({
            requestOauthRegisterCode: "user/requestOauthRegisterCode",
            requestGetAuth: "user/requestGetAuth"
        }),
        onPageToAgree() {
            this.$router.push({
                name: "agree",
            });
        },
        onPageToCopyright() {
            this.$router.push({
                name: "copyright",
            });
        },
        onSubmit() {
            if (this.code) {
                this.requestOauthRegisterCode({ invatation_code: this.code }).then(() => {

                    if (this.$cookies.isKey('share_invite_code')) this.$cookies.remove('share_invite_code')  //如果cookies有invite_code，销毁他人邀请码

                    this.pageToNext();
                })
            } else {
                this.pageToNext();
            }
        },
        pageToNext() {
            let _url = this.$route.query.url2

            if (_url == '/products') {
                let _keywords = this.$route.query.keywords || ''
                let _option = this.$route.query.option || 0
                this.$router.replace({
                    name: 'products',
                    params: { keywords: _keywords, option: _option },
                    query: { keywords: _keywords, option: _option, new_reg: 1 }
                }).then(() => {
                    sessionStorage.removeItem('history_keywords')
                });
            } else {
                this.$router.replace({
                    path: _url || '/',
                    query: { new_reg: 1 },
                });
            }
        }
    },
    created() {
        if (this.$cookies.isKey('share_invite_code')) {
            this.code = this.$cookies.get('share_invite_code')
            this.codeDisabled = true;
        }

        if (this.$route.query.secret && !(this.$cookies.isKey('user_id'))) {
            this.requestGetAuth({ secret: this.$route.query.secret })
        }
    }
}
</script>

<style>
.invite{padding-top: 47px; height: 100vh; background: #fff;}
.invite-form{padding:20px 20px 0;}
.invite-form h2{font-size: 20px;color: #2E2E30; text-align: center; font-weight: normal; }
.invite-form .icon{ margin: 32px auto 0;}
.invite-form .cue{margin-top: 20px;}
.invite-form .cue h3{ text-align: center; font-size: 14px; font-weight: normal; }
.invite-form .cue h3.s_1{color: #2E2E30;}
.invite-form .cue h3.s_2{color: #B3B3B3;}
.invite-form .form{margin-top: 40px;}
.invite-form .form .style2 .van-field__body{background: #fff; border: 1px solid #B3B3B3;margin-bottom: 20px;}
.invite-agree{margin-bottom: 32px;font-size: 12px; color: #B3B3B3; }
.invite-agree i{font-style: normal; color: #2E2E30;}
</style>